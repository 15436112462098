import { datadogRum } from '@datadog/browser-rum';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminPath from 'Routes/path/adminPath';
import { getUserDetails } from 'slices/admin/auth/reducer';

const LayoutMenuData = () => {
  const history = useNavigate();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);

  // Master Curriculum
  const [isMasterCurriculum, setIsMasterCurriculum] = useState(false);
  const [isSchoolCurriculum, setIsSchoolCurriculum] = useState(false);
  const [isMlBook, setIsMlBook] = useState(false);
  const [isMlChapter, setIsMlChapter] = useState(false);
  const [isMlTopic, setIsMlTopic] = useState(false);
  const [isMlSubTopic, setIsMlSubTopic] = useState(false);
  const [isMlSegment, setIsMlSegment] = useState(false);

  // Schools
  const [isSchools, setIsSchools] = useState(false);
  const [isSchoolList, setIsSchoolList] = useState(false);

  // Classes
  const [isClasses, setIsClasses] = useState(false);
  const [isClassList, setIsClassList] = useState(false);

  // Practice
  const [isPractice, setIsPractice] = useState(false);
  const [isSheet, setIsSheet] = useState(false);
  const [isPracticeClasses, setIsPracticeClasses] = useState(false);
  const [isPracticeTracker, setIsPracticeTracker] = useState(false);

  // evaluations
  const [isEvaluations, setIsEvaluations] = useState(false);
  const [isEvaluationsList, setIsEvaluationsList] = useState(false);

  // Batches
  const [isBatches, setIsBatches] = useState(false);
  const [isBatchList, setIsBatchList] = useState(false);

  // Configurations
  const [isConfigurations, setIsConfigurations] = useState(false);
  const [isClassConfigList, setIsClassConfigList] = useState(false);
  const [isContentConfigList, setIsContentConfigList] = useState(false);
  const [isSanityAssetList, setIsSanityAssetList] = useState(false);

  // Presets
  const [isPresets, setIsPresets] = useState(false);
  const [isPresetsList, setIsPresetsList] = useState(false);
  const [isDemoClassList, setIsDemoClassList] = useState(false);
  const [isSubscriptions, setIsSubscriptions] = useState(false);
  const [isSubscriptionsList, setIsSubscriptionsList] = useState(false);
  const [isPaymentList, setIsPaymentList] = useState(false);

  //Users
  const [isUsers, setIsUsers] = useState(false);
  const [isUserList, setIsUserList] = useState(false);

  //Student/Guardian
  const [isStudentGuardian, setIsStudentGuardian] = useState(false);
  const [isStudentList, setIsStudentList] = useState(false);
  const [isGuardianList, setIsGuardianList] = useState(false);

  //ClassSummaries
  const [isClassSummariesList, setIsClassSummariesList] = useState(false);

  //Feedback
  const [isFeedbackList, setIsFeedbackList] = useState(false);

  const [isOngoingClassList, setIsOngoingClassList] = useState(false);
  const [isPastClassList, setIsPastClassList] = useState(false);

  const userDetails = useSelector(getUserDetails);

  //QC
  const [isQCList, setIsQCList] = useState(false);
  const [isBatchQCList, setIsBatchQCList] = useState(false);
  const [isChapterQCList, setIsChapterQCList] = useState(false);
  const [isStudentQCList, setIsStudentQCList] = useState(false);

  useEffect(() => {
    if (userDetails) {
      datadogRum.setUser({
        id: userDetails?.id,
        name: `${userDetails?.firstName} ${userDetails?.lastName}`.trim(),
        email: userDetails?.email,
        roleId: userDetails?.roleId,
      });
    }
  }, [userDetails]);

  const [isCurrentState, setIsCurrentState] = useState(false);
  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        // item.demoClassList.remove('active');
        var id = item.getAttribute('subitems');
        if (document.getElementById(id)) {
          document.getElementById(id).classList.remove('show');
          document.getElementById(id).demoClassList.remove('show');
          document.getElementById(id).demoClassesList.remove('show');
        }
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    if (isCurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
    if (isCurrentState !== 'masterCurriculum') {
      setIsMasterCurriculum(false);
    }
    if (isCurrentState !== 'curriculum') {
      setIsSchoolCurriculum(false);
    }
    if (isCurrentState !== 'school') {
      setIsSchools(false);
    }
    if (isCurrentState !== 'classes') {
      setIsClasses(false);
    }
    if (isCurrentState !== 'practice') {
      setIsPractice(false);
    }
    if (isCurrentState !== 'evaluations') {
      setIsEvaluations(false);
    }
    if (isCurrentState !== 'batches') {
      setIsBatches(false);
    }
    if (isCurrentState !== 'configurations') {
      setIsConfigurations(false);
    }
    if (isCurrentState !== 'presets') {
      setIsPresets(false);
    }
    if (isCurrentState !== 'users') {
      setIsUserList(false);
    }
    if (isCurrentState !== 'studentGuardian') {
      setIsStudentGuardian(false);
    }
    if (isCurrentState !== 'subscriptions') {
      setIsSubscriptions(false);
    }
  }, [
    history,
    isCurrentState,
    isDashboard,
    isMasterCurriculum,
    isSchools,
    isClasses,
    isPractice,
    isEvaluations,
    isBatches,
    isPresets,
    isConfigurations,
    isUsers,
    isStudentGuardian,
  ]);

  const getMenuItems = () => {
    const { permissions } = userDetails;
    const permissionsIds = permissions.map(
      (permissionData) => permissionData.moduleName,
    );
    permissionsIds.unshift('dashboard');

    const menuItems = [
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: 'mdi mdi-speedometer',
        link: AdminPath.DASHBOARD,
        stateVariables: isDashboard,
        click: function (e) {
          e.preventDefault();
          setIsDashboard(!isDashboard);
          setIsCurrentState('Dashboard');
          updateIconSidebar(e);
        },
      },
      {
        id: 'master-curriculum',
        label: 'Master Curriculum',
        icon: 'mdi mdi-book-multiple',
        link: '/#',
        click: function (e) {
          e.preventDefault();
          setIsMasterCurriculum(!isMasterCurriculum);
          setIsCurrentState('masterCurriculum');
          updateIconSidebar(e);
        },
        stateVariables: isMasterCurriculum,
        subItems: [
          {
            parentId: 'mlSchoolCurriculums',
            label: 'School Curriculums',
            link: '/master-curriculum/curriculums',
            click: function (e) {
              setIsSchoolCurriculum(!isSchoolCurriculum);
            },
            isChildItem: false,
            stateVariables: isSchoolCurriculum,
          },
          {
            id: 'book',
            label: 'Books',
            link: '/#',
            isChildItem: true,
            click: function (e) {
              e.preventDefault();
              setIsMlBook(!isMlBook);
            },
            stateVariables: isMlBook,
            childItems: [
              {
                id: 2,
                label: 'List Of Books',
                link: '/master-curriculum/books/list',
                parentId: 'mlBooks',
              },
            ],
          },
          {
            id: 'chapter',
            label: 'Chapters',
            link: '/#',
            isChildItem: true,
            click: function (e) {
              e.preventDefault();
              setIsMlChapter(!isMlChapter);
            },
            stateVariables: isMlChapter,
            childItems: [
              {
                id: 2,
                label: 'List Of Chapters',
                link: '/master-curriculum/chapters/list',
                parentId: 'mlChapters',
              },
            ],
          },
          {
            id: 'topic',
            label: 'Topics',
            link: '/#',
            isChildItem: true,
            click: function (e) {
              e.preventDefault();
              setIsMlTopic(!isMlTopic);
            },
            stateVariables: isMlTopic,
            childItems: [
              {
                id: 2,
                label: 'List Of Topics',
                link: '/master-curriculum/topics/list',
                parentId: 'mlTopics',
              },
            ],
          },
          {
            id: 'sub-topic',
            label: 'SubTopics',
            link: '/#',
            isChildItem: true,
            click: function (e) {
              e.preventDefault();
              setIsMlSubTopic(!isMlSubTopic);
            },
            stateVariables: isMlSubTopic,
            childItems: [
              {
                id: 2,
                label: 'List Of SubTopics',
                link: '/master-curriculum/subtopics/list',
                parentId: 'mlSubTopics',
              },
            ],
          },
          {
            id: 'segments',
            label: 'Segments',
            link: '/#',
            isChildItem: true,
            click: function (e) {
              e.preventDefault();
              setIsMlSegment(!isMlSegment);
            },
            stateVariables: isMlSegment,
            childItems: [
              {
                id: 2,
                label: 'List Of Segments',
                link: '/master-curriculum/segments/list',
                parentId: 'mlSegment',
              },
              {
                id: 3,
                label: 'List Of Items',
                link: '/master-curriculum/segments/items/list',
                parentId: 'mlItems',
              },
            ],
          },
        ],
      },
      {
        id: 'school',
        label: 'Schools',
        icon: 'mdi mdi-town-hall',
        link: AdminPath.LIST_OF_SCHOOLS,
        click: function (e) {
          e.preventDefault();
          setIsSchoolList(!isSchoolList);
        },
        stateVariables: isSchoolList,
        isChildItem: false,
      },
      {
        id: 'classes',
        label: 'Classes',
        icon: 'mdi mdi-google-classroom',
        link: AdminPath.LIST_OF_CLASSES,
        isChildItem: false,
        click: function (e) {
          e.preventDefault();
          setIsClassList(!isClassList);
        },
        stateVariables: isClassList,
      },
      {
        id: 'practice',
        label: 'Practice Sheets',
        icon: 'mdi mdi-google-classroom',
        link: '/#',
        isChildItem: true,
        click: function (e) {
          e.preventDefault();
          setIsPractice(!isPractice);
          setIsCurrentState('practice');
          updateIconSidebar(e);
        },
        stateVariables: isPractice,
        subItems: [
          {
            id: 'sheets',
            label: 'Sheets',
            link: AdminPath.LIST_OF_PRACTICE_SHEET,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsSheet(!isSheet);
            },
            stateVariables: isSheet,
          },
          //TODO: HAVE TO IMPLEMENT THE PRACTICE CLASS DASHBOARD
          // {
          //   id: 'classes',
          //   label: 'Classes',
          //   link: AdminPath.LIST_OF_PRACTICE_CLASSES,
          //   isChildItem: false,
          //   click: function (e) {
          //     e.preventDefault();
          //     setIsPracticeClasses(!isPracticeClasses);
          //   },
          //   stateVariables: isPracticeClasses,
          // },
          {
            id: 'tracker',
            label: 'Tracker',
            link: AdminPath.LIST_OF_PRACTICE_TRACKER,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsPracticeTracker(!isPracticeTracker);
            },
            stateVariables: isPracticeTracker,
          },
        ],
      },
      {
        id: 'batches',
        label: 'Batches',
        icon: 'mdi mdi-format-list-group',
        link: AdminPath.LIST_OF_BATCHES,
        isChildItem: false,
        click: function (e) {
          e.preventDefault();
          setIsBatchList(!isBatchList);
        },
        stateVariables: isBatchList,
      },
      {
        id: 'classes',
        label: 'OnGoing Classes',
        icon: 'mdi mdi-account-group-outline',
        link: '/#',
        isChildItem: true,
        click: function (e) {
          e.preventDefault();
          setIsOngoingClassList(!isOngoingClassList);
        },
        stateVariables: isOngoingClassList,
        subItems: [
          {
            id: 'classes',
            label: 'Tracker',
            icon: 'mdi mdi-google-classroom',
            link: `${AdminPath.LIST_OF_CLASS_SUMMARIES}`,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsClassSummariesList(!isClassSummariesList);
            },
            stateVariables: isClassSummariesList,
          },
        ],
      },
      {
        id: 'classes',
        label: 'Past Classes',
        icon: 'mdi mdi-account-group-outline',
        link: '/#',
        isChildItem: true,
        click: function (e) {
          e.preventDefault();
          setIsPastClassList(!isPastClassList);
        },
        stateVariables: isPastClassList,
        subItems: [
          {
            id: 'classes',
            label: 'Feedback',
            icon: 'mdi mdi-google-classroom',
            link: AdminPath.LIST_OF_FEEDBACKS,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsFeedbackList(!isFeedbackList);
            },
            stateVariables: isFeedbackList,
          },
          {
            id: 'evaluations',
            label: 'Evaluations',
            icon: 'mdi mdi-google-classroom',
            link: AdminPath.LIST_OF_EVALUATIONS,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsEvaluationsList(!isEvaluationsList);
            },
            stateVariables: isEvaluationsList,
          },
        ],
      },
      {
        id: 'reports',
        label: 'Reports',
        icon: 'mdi mdi-file-document-multiple-outline',
        link: AdminPath.REPORT,
        click: function (e) {
          e.preventDefault();
        },
      },
      {
        id: 'configuration',
        label: 'Content',
        icon: 'mdi mdi-format-list-group',
        link: '/#',
        click: function (e) {
          e.preventDefault();
          setIsConfigurations(!isConfigurations);
          setIsCurrentState('configurations');
          updateIconSidebar(e);
        },
        stateVariables: isConfigurations,
        subItems: [
          {
            id: 'sanityAssets',
            label: 'Upload Sanity Assets',
            link: AdminPath.SANITY_ASSETS,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsClassConfigList(!isClassConfigList);
            },
            stateVariables: isClassConfigList,
          },
          {
            id: 'classConfig',
            label: 'Class Config',
            link: AdminPath.CLASS_CONFIG,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsSanityAssetList(!isSanityAssetList);
            },
            stateVariables: isSanityAssetList,
          },
          {
            id: 'contentConfig',
            label: 'Content Config',
            link: AdminPath.CONTENT_CONFIG,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsContentConfigList(!isContentConfigList);
            },
            stateVariables: isContentConfigList,
          },
        ],
      },
      {
        id: 'presets',
        label: 'Demo Presets',
        icon: 'mdi mdi-format-list-group',
        link: '/#',
        click: function (e) {
          e.preventDefault();
          setIsPresets(!isPresets);
          setIsCurrentState('presets');
          updateIconSidebar(e);
        },
        stateVariables: isPresets,
        subItems: [
          {
            id: 'demoTimingList',
            label: 'Demo Timings Preset',
            link: AdminPath.LIST_OF_DEMO_CLASS_TIMINGS,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsPresetsList(!isPresetsList);
            },
            stateVariables: isPresetsList,
          },
          {
            id: 'demoClassList',
            label: 'Demo Class Preset',
            link: AdminPath.LIST_OF_DEMO_CLASS_CONFIGURATIONS,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsDemoClassList(!isDemoClassList);
            },
            stateVariables: isDemoClassList,
          },
        ],
      },
      {
        id: 'user',
        label: 'Users',
        icon: 'mdi mdi-account-group-outline',
        link: '/user/list',
        click: function (e) {
          e.preventDefault();
          setIsUserList(!isUserList);
        },
        stateVariables: isUsers,
      },
      {
        id: 'students',
        label: 'Students',
        icon: 'mdi mdi-account-group-outline',
        link: AdminPath.LIST_OF_STUDENTS,
        isChildItem: false,
        click: function (e) {
          e.preventDefault();
          setIsStudentList(!isStudentList);
        },
        stateVariables: isStudentList,
      },
      {
        id: 'guardian',
        icon: 'mdi mdi-account-group-outline',
        label: 'Guardians',
        link: AdminPath.LIST_OF_GUARDIANS,
        isChildItem: false,
        click: function (e) {
          e.preventDefault();
          setIsGuardianList(!isGuardianList);
        },
        stateVariables: isGuardianList,
      },
      {
        id: 'qcDashboards',
        label: 'QC GAPS',
        icon: 'mdi mdi-format-list-group',
        isChildItem: false,
        click: function (e) {
          e.preventDefault();
          setIsQCList(!isQCList);
        },
        stateVariables: isQCList,
        subItems: [
          {
            id: 'studentQc',
            label: 'Student QC GAP',
            link: AdminPath.LIST_OF_STUDENT_QC_GAP,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsStudentQCList(!isStudentQCList);
            },
            stateVariables: isStudentQCList,
          },
          {
            id: 'batchesQC',
            label: 'Batch QC GAP',
            icon: 'mdi mdi-google-classroom',
            link: `${AdminPath.LIST_OF_BATCH_QC_GAP}`,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsBatchQCList(!isBatchQCList);
            },
            stateVariables: isBatchQCList,
          },
          {
            id: 'chapterQC',
            label: 'Chapter QC GAP',
            icon: 'mdi mdi-google-classroom',
            link: `${AdminPath.LIST_OF_CHAPTER_QC_GAP}`,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsChapterQCList(!isChapterQCList);
            },
            stateVariables: isChapterQCList,
          },
        ],
      },
      {
        id: 'subscriptions',
        label: 'Subscriptions',
        icon: 'mdi mdi-format-list-group',
        link: '/#',
        click: function (e) {
          e.preventDefault();
          setIsSubscriptions(!isSubscriptions);
          setIsCurrentState('subscriptions');
          updateIconSidebar(e);
        },
        stateVariables: isSubscriptions,
        subItems: [
          {
            id: 'subscription',
            label: 'Subscription',
            link: AdminPath.LIST_OF_SUBSCRIPTIONS,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsSubscriptionsList(!isSubscriptionsList);
            },
            stateVariables: isSubscriptionsList,
          },
          {
            id: 'payment',
            label: 'Payment',
            link: AdminPath.LIST_OF_PAYMENTS,
            isChildItem: false,
            click: function (e) {
              e.preventDefault();
              setIsPaymentList(!isPaymentList);
            },
            stateVariables: isPaymentList,
          },
        ],
      },
    ];
    const menuData = menuItems.filter(
      (menuitem) => permissionsIds.includes(menuitem.id) && menuitem,
    );

    return menuData;
  };

  return <React.Fragment>{getMenuItems()}</React.Fragment>;
};

export default LayoutMenuData;
