import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import RoutePath from './path/adminPath';
import { Reports } from 'pages/Admin/Reports';
/*************(Admin)***************/

/*****Dashboard******/
const Dashboard = lazy(() => import('pages/Admin/Dashboard'));
// const AdminDashboard = lazy(
//   () => import('pages/Admin/Dashboard/AdminDashboard'),
// );

const AdminDashboard = lazy(
  () => import('pages/Admin/Dashboard/DashboardV2/AdminDashboard'),
);

/*****Books (Master Curriculum)******/
const ListSchoolCurriculums = lazy(
  () =>
    import(
      'pages/Admin/MasterCurriculum/SchoolCurriculums/ListSchoolCurriculums'
    ),
);

const CreateBook = lazy(
  () => import('pages/Admin/MasterCurriculum/Books/CreateBook'),
);
const ListBooks = lazy(
  () => import('pages/Admin/MasterCurriculum/Books/ListBooks'),
);

/*****Chapters (Master Curriculum)******/
const CreateChapter = lazy(
  () => import('pages/Admin/MasterCurriculum/Chapters/CreateChapter'),
);
const ListChapters = lazy(
  () => import('pages/Admin/MasterCurriculum/Chapters/ListChapters'),
);

/*****Topics (Master Curriculum)******/
const CreateTopic = lazy(
  () => import('pages/Admin/MasterCurriculum/Topics/CreateTopic'),
);
const ListTopics = lazy(
  () => import('pages/Admin/MasterCurriculum/Topics/ListTopics'),
);

/*****SubTopics (Master Curriculum)******/
const CreateSubTopic = lazy(
  () => import('pages/Admin/MasterCurriculum/SubTopics/CreateSubTopic'),
);
const ListSubTopics = lazy(
  () => import('pages/Admin/MasterCurriculum/SubTopics/ListSubTopics'),
);

/*****Segments (Master Curriculum)******/
// const CreateSegment = lazy(
//   () => import('pages/Admin/MasterCurriculum/Segments/CreateSegment'),
// );
const ListSegments = lazy(
  () => import('pages/Admin/MasterCurriculum/Segments/ListSegments'),
);
const ListItems = lazy(
  () => import('pages/Admin/MasterCurriculum/Segments/Items/ListItems'),
);

/*****Schools******/
const CreateSchool = lazy(() => import('pages/Admin/Schools/CreateSchool'));
const ListSchools = lazy(() => import('pages/Admin/Schools/ListSchools'));

/*****School Curriculum Groups******/
const ListSchoolCurriculumGroups = lazy(
  () => import('pages/Admin/SchoolCurriculumGroups/ListSchoolCurriculumGroups'),
);

/*****Batches******/
const ListBatches = lazy(() => import('pages/Admin/Batches/ListBatches'));
const ListBatchesV2 = lazy(() => import('pages/Admin/Batches/V2Grids/index'));

const ListDemoBatches = lazy(
  () => import('pages/Admin/Batches/ListDemoBatches'),
);

/*****Classes******/
const ListClasses = lazy(() => import('pages/Admin/Classes/ListClasses'));
const ListClassesV2 = lazy(
  () => import('pages/Admin/Classes/V2Grids/ListClasses'),
);

/*******Practice*********/
const PracticeSheetList = lazy(() => import('pages/Admin/Practice/Sheet'));
const PracticeClassesList = lazy(() => import('pages/Admin/Practice/Classes'));
const PracticeTracker = lazy(() => import('pages/Admin/Practice/Tracker'));

/*******Evaluations*********/
const EvaluationList = lazy(
  () => import('pages/Admin/Evaluations/EvaluationList'),
);

const DemoClassConfigurations = lazy(
  () => import('pages/Admin/Presets/Configurations'),
);
const DemoClasses = lazy(() => import('pages/Admin/Classes/DemoClasses'));

/*****Users******/
const ListUsers = lazy(() => import('pages/Admin/Users/Others'));
const ListStudents = lazy(() => import('pages/Admin/Users/Students'));
const ListStudentsV2 = lazy(() => import('pages/Admin/Users/V2Grids/Students'));
const ListFeedbacks = lazy(
  () => import('pages/Admin/Classes/V2Grids/Feedback/FeedbackList'),
);
const ListStudentClasses = lazy(
  () => import('pages/Admin/Users/Students/ListStudentClasses'),
);
const ListGuardians = lazy(() => import('pages/Admin/Users/Guardians'));

/*****Configurations******/
const DemoClassTimings = lazy(() => import('pages/Admin/Presets/Timings'));

const ContentConfiguration = lazy(
  () => import('pages/Admin/Configurations/ContentConfiguration'),
);
const ClassConfiguration = lazy(
  () => import('pages/Admin/Configurations/ClassConfiguration'),
);
const SanityAssets = lazy(
  () => import('pages/Admin/Configurations/SanityAssets'),
);

const ListSubscription = lazy(
  () => import('pages/Admin/Subscriptions/Subscription'),
);
const ListPayment = lazy(() => import('pages/Admin/Subscriptions/Payment'));
const ListClassSummaries = lazy(() => import('pages/Admin/ClassSummaries'));
const ListStudentQCGap = lazy(
  () => import('pages/Admin/QcDashboards/StudentQcGap'),
);

const ListBatchQCGap = lazy(
  () => import('pages/Admin/QcDashboards/BatchesQC/index'),
);
const ListChapterQCGap = lazy(
  () => import('pages/Admin/QcDashboards/ChapterQc/index'),
);

const adminRoute = [
  { path: RoutePath.DASHBOARD, component: <AdminDashboard /> },
  {
    path: RoutePath.LIST_OF_SCHOOL_CURRICULUM,
    component: <ListSchoolCurriculums />,
  },
  // { path: RoutePath.DASHBOARD, component: <AdminDashboard /> },
  { path: RoutePath.CREATE_BOOK, component: <CreateBook /> },
  { path: RoutePath.LIST_OF_BOOKS, component: <ListBooks /> },
  { path: RoutePath.CREATE_CHAPTER, component: <CreateChapter /> },
  { path: RoutePath.LIST_OF_CHAPTERS, component: <ListChapters /> },
  { path: RoutePath.CREATE_TOPIC, component: <CreateTopic /> },
  { path: RoutePath.LIST_OF_TOPICS, component: <ListTopics /> },
  { path: RoutePath.CREATE_SUBTOPIC, component: <CreateSubTopic /> },
  { path: RoutePath.LIST_OF_SUBTOPICS, component: <ListSubTopics /> },
  // { path: RoutePath.CREATE_SEGMENTS, component: <CreateSubTopic /> },
  { path: RoutePath.LIST_OF_SEGMENTS, component: <ListSegments /> },
  // { path: RoutePath.CREATE_ITEMS, component: <CreateItems /> },
  { path: RoutePath.LIST_OF_ITEMS, component: <ListItems /> },
  { path: RoutePath.LIST_OF_SCHOOLS, component: <ListSchools /> },
  { path: RoutePath.CREATE_SCHOOL, component: <CreateSchool /> },
  {
    path: RoutePath.LIST_OF_SCHOOL_GROUP_CURRICULUM,
    component: <ListSchoolCurriculumGroups />,
  },
  // { path: RoutePath.LIST_OF_BATCHES, component: <ListBatches /> },
  { path: RoutePath.LIST_OF_BATCHES, component: <ListBatchesV2 /> },
  { path: RoutePath.LIST_OF_DEMO_BATCHES, component: <ListDemoBatches /> },
  // { path: RoutePath.LIST_OF_CLASSES, component: <ListClasses /> },
  { path: RoutePath.LIST_OF_CLASSES, component: <ListClassesV2 /> },
  { path: RoutePath.LIST_OF_PRACTICE_SHEET, component: <PracticeSheetList /> },
  {
    path: RoutePath.LIST_OF_PRACTICE_CLASSES,
    component: <PracticeClassesList />,
  },
  { path: RoutePath.LIST_OF_PRACTICE_TRACKER, component: <PracticeTracker /> },
  { path: RoutePath.LIST_OF_EVALUATIONS, component: <EvaluationList /> },

  {
    path: RoutePath.LIST_OF_DEMO_CLASS_CONFIGURATIONS,
    component: <DemoClassConfigurations />,
  },
  { path: RoutePath.LIST_OF_DEMO_CLASSES_V1, component: <DemoClasses /> },
  { path: RoutePath.LIST_OF_USERS, component: <ListUsers /> },
  // { pat h: RoutePath.LIST_OF_STUDENTS, component: <ListStudents /> },
  { path: RoutePath.LIST_OF_STUDENTS, component: <ListStudentsV2 /> },
  { path: RoutePath.LIST_OF_STUDENT_QC_GAP, component: <ListStudentQCGap /> },
  {
    path: RoutePath.LIST_OF_STUDENTS_CLASSES,
    component: <ListStudentClasses />,
  },
  { path: RoutePath.LIST_OF_GUARDIANS, component: <ListGuardians /> },
  {
    path: RoutePath.LIST_OF_DEMO_CLASS_TIMINGS,
    component: <DemoClassTimings />,
  },
  { path: RoutePath.INDEX, exact: true, component: <Navigate to="/login" /> },
  { path: RoutePath.NOT_FOUND, component: <Navigate to="/login" /> },
  { path: RoutePath.CONTENT_CONFIG, component: <ContentConfiguration /> },
  { path: RoutePath.CLASS_CONFIG, component: <ClassConfiguration /> },

  { path: RoutePath.LIST_OF_SUBSCRIPTIONS, component: <ListSubscription /> },
  { path: RoutePath.LIST_OF_PAYMENTS, component: <ListPayment /> },

  { path: RoutePath.SANITY_ASSETS, component: <SanityAssets /> },
  { path: RoutePath.LIST_OF_FEEDBACKS, component: <ListFeedbacks /> },
  { path: RoutePath.REPORT, component: <Reports /> },
  {
    path: RoutePath.LIST_OF_CLASS_SUMMARIES,
    component: <ListClassSummaries />,
  },

  { path: RoutePath.LIST_OF_BATCH_QC_GAP, component: <ListBatchQCGap /> },
  { path: RoutePath.LIST_OF_CHAPTER_QC_GAP, component: <ListChapterQCGap /> },
];

export default adminRoute;
