import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

export const sendReport = (payload) => {
  return api.create(url.POST_REPORT, payload);
};

export const qcGapReport = (reportType) => {
  return api.get(`${url.QC_GAP_REPORT}/${reportType}`);
};
