export const BREADCRUMBS = {
  ACTIVITIES: 'Activities',
  STUDENT: 'Student',
  CALENDAR: 'Calendar',
  DASHBOARD: 'Dashboard',
  PROFILE: 'Profile',
};

export const coachArrays = [
  'coach_basic',
  'coach_intermediate',
  'coach_advanced',
];

export const MODULES = {
  BATCHES: 'BATCHES',
  BATCH_TYPES: 'BATCH_TYPES',
  CLASSES: 'CLASSES',
  SCHOOLS: 'SCHOOLS',
  STUDENTS: 'STUDENTS',
  CITIES: 'CITIES',
  BOARDS: 'BOARDS',
  CURRICULUMS: 'CURRICULUMS',
  GRADES: 'GRADES',
  TUTORS: 'TUTORS',
  CLASS_CONFIGURATION: 'CLASS_CONFIGURATION',
  CONTENT_CONFIGURATION: 'CONTENT_CONFIGURATION',
  SUBJECTS: 'SUBJECTS',
  SAM: 'SAM',
  CONFIGURATION: 'CONFIGURATION',
  SEGMENTS: 'SEGMENTS',
  BOOKS: 'BOOKS',
  ROLES: 'ROLES',
  SUBSCRIPTIONS: 'UPRIO_SUBSCRIPTIONS',
  CLASS_FEEDBACKS: 'CLASS_FEEDBACKS',
  CLASS_SUMMARIES: 'CLASS_SUMMARIES',
  PRACTICE_MODULE: 'PRACTICE',
  EVALUATIONS: 'EVALUATIONS',
  LECTURES: 'LECTURES',
  CHAPTERS: 'CHAPTERS',
};

export const EVALUATION_STATUS = {
  COMPLETED: 'completed',
  SCHEDULED: 'scheduled',
  EXPIRED: 'expired',
  INPROGRESS: 'on-going',
};

export const CLASS_TYPE_OPTIONS = [
  { value: 'regular_class', label: 'Regular Class' },
  { value: 'practice_sheet', label: 'Practice Sheet' },
  { value: 'intervention_class', label: 'Intervention Class' },
  { value: 'evaluation_class', label: 'Evaluation Class' },
  { value: 'revision_class', label: 'Revision Class' },
  { value: 'demo_class', label: 'Demo Class' },
  { value: 'mock_paper', label: 'Mock Paper' },
];

export const EVALUATION_TYPE_OPTIONS = [
  { value: 'notebook_evaluation', label: 'Notebook' },
  { value: 'poll_evaluation', label: 'Poll' },
  { value: 'oral_evaluation', label: 'Oral' },
];

export const STUDENT_EVALUATION_EXPIRED = [
  { value: 'not_passed', label: 'Not Passed' },
  { value: 'passed_in_2_days', label: 'Passed in last 2 days' },
  { value: 'passed_2_to_1w', label: 'Passed (2 days - 1 week)' },
  { value: 'passed_greater_than_1w', label: 'Passed (> 1 week)' },
];

export const TUTOR_REVIEW_SUBMISSION = [
  { value: 'blank_submission', label: 'Blank Submission' },
  { value: 'partial_submission', label: 'Partial Submission' },
  {
    value: 'wrong_submission',
    label: 'Wrong Submission( Not related to the practice sheet )',
  },
  { value: 'correct_submission', label: 'Correct Submission' },
];

export const CLASS_STATUS_OPTIONS = [
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Ended',
    value: 'ended',
  },
];

export const CONFIG_OPTION = [
  { value: 'classConfig', label: 'Class Config' },
  { value: 'lectureConfig', label: 'Lecture Config' },
];

export const PRACTICE_SHEET_STATUS = [
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
];

export const BATCH_TYPES = {
  DEMO: 'DEMO',
  REGULAR: 'REGULAR',
  REVISION: 'REVISION',
  PRACTICE: 'PRACTICE',
  OTHER: 'OTHER',
};

export const EPIC_SEARCH_HELPER = {
  [MODULES.STUDENTS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.SCHOOLS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.BATCHES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.BATCH_TYPES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.BOARDS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.CITIES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.TUTORS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.CLASSES]: {
    displayKey: 'name',
    valueKey: 'id',
    labelRender: (result) => `${result['name']}\n${result['id']}`,
  },
  [MODULES.CLASS_CONFIGURATION]: {
    displayKey: 'name',
    valueKey: 'id',
    labelRender: (result) => `${result['name']}\n${result['referenceId']}`,
  },
  [MODULES.CURRICULUMS]: {
    displayKey: 'referenceId',
    valueKey: 'id',
  },
  [MODULES.GRADES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.SUBJECTS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.SAM]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.SEGMENTS]: {
    displayKey: 'name',
    valueKey: 'id',
    labelRender: (result) => `${result['name']}\n${result['referenceId']}`,
  },
  [MODULES.CONTENT_CONFIGURATION]: {
    displayKey: 'referenceId',
    valueKey: 'id',
  },
  [MODULES.SUBSCRIPTIONS]: {
    displayKey: 'status',
    valueKey: 'id',
  },
  [MODULES.BOOKS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.ROLES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.CLASS_FEEDBACKS]: {
    displayKey: 'status',
    valueKey: 'id',
  },
  [MODULES.CLASS_SUMMARIES]: {
    displayKey: 'className',
    valueKey: 'classId',
  },
};

export const EPIC_SANITY_SEARCH_HELPER = {
  [MODULES.BOARDS]: {
    displayKey: 'name',
    valueKey: '_id',
  },
  [MODULES.GRADES]: {
    displayKey: 'name',
    valueKey: '_id',
  },
  [MODULES.CHAPTERS]: {
    displayKey: 'name',
    valueKey: '_id',
  },
  [MODULES.LECTURES]: {
    displayKey: 'title',
    valueKey: '_id',
  },
};

export const SELECT_CUSTOM_STYLES = {
  multiValue: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: 'var(--vz-primary)',
    color: 'var(--vz-white)',
  }),
  multiValueLabel: (defaultStyles) => ({
    ...defaultStyles,
    color: 'var(--vz-white)',
    whiteSpace: 'pre-wrap',
  }),
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isFocused ? 'var(--vz-white)' : 'var(--vz-black)',
    backgroundColor: state.isFocused ? 'var(--vz-primary)' : 'var(--vz-white)',
    whiteSpace: 'pre-wrap',
  }),
};

export const PAYMENT_AGGREGATOR = {
  RAZORPAY: 'RAZORPAY',
  PHONEPE: 'PHONEPE',
};

export const SHOW_PLUGIN = {
  IFRAME: 'iframe',
  WHITEBOARD: 'whiteboard',
};

export const SHOW_TAB = {
  PREVIEW: 'preview',
  EVALUATION: 'evaluation',
};

export const PAYMENT_ROLES = ['SAM_MANAGER', 'SUPER_ADMIN'];
