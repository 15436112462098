import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import packageJson from '../package.json';

import App from 'App';
import reportWebVitals from 'reportWebVitals';
import rootReducer from 'slices';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'Layout',
    'StudentGuardianRegistration',
    'StudentGuardianLogin',
    'StudentGuardianProfile',
    'Utils',
    'AdminAuth',
    'ContentInClass',
    'SlideStatus',
  ],
};

function getServiceNameFromEnv() {
  switch (process.env.REACT_APP_DOMAIN_ENV) {
    case 'development':
      return 'dev-lms-frontend';
    case 'staging':
      return 'stage-lms-frontend';
    case 'production':
      return 'lms-frontend';
    default:
      return 'dev-lms-frontend';
  }
}

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: getServiceNameFromEnv(),
  env: process.env.REACT_APP_DOMAIN_ENV,
  version: packageJson.version,
});

if (process.env.REACT_APP_DOMAIN_ENV === 'production') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: getServiceNameFromEnv(),
    env: process.env.REACT_APP_DOMAIN_ENV,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

const pRootReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pRootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: true,
});

export const persister = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { store };
