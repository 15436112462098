import { combineReducers } from 'redux';

// Front
import LayoutReducer from './layouts/reducer';

// Authentication (Students/Guardians)
import StudentGuardianLoginReducer from './student-guardian/auth/login/reducer';
import StudentGuardianRegisterReducer from './student-guardian/auth/register/reducer';
import StudentGuardianForgotPasscodeReducer from './student-guardian/auth/forgotPasscode/reducer';

// Account (Students/Guardians)
import StudentGuardianDashboardReducer from './student-guardian/account/dashboard/reducer';
import StudentGuardianProfileReducer from './student-guardian/account/profile/reducer';

// Utils
import UtilsReducer from './utils/reducer';

//admin
import adminAuthReducer from './admin/auth/reducer';
import adminSchoolsReducer from './admin/school/reducer';
import adminSchoolCurriculumReducer from './admin/schoolCurriculum/reducer';
import adminClassReducer from './admin/class/reducer';

import adminBatchReducer from './admin/batch/reducer';
import adminUserReducer from './admin/user/reducer';
import adminTutorReducer from './admin/tutor/reducer';
import adminDemoClassReducer from './admin/demoClass/reducer';
import sanityReducer from './admin/sanity/reducer';
import adminConfigurationReducer from './admin/configuration/reducer';
import adminDashboardReducer from './admin/dashboard/reducer';

import studentReducer from './student/reducer';
import adminBookReducer from './admin/books/reducer';
import adminChapterReducer from './admin/chapter/reducer';
import adminTopicReducer from './admin/topic/reducer';
import adminSubtopicReducer from './admin/subTopic/reducer';
import adminSegmentReducer from './admin/segment/reducer';
import adminItemReducer from './admin/segment/item/reducer';

import socketInstanceReducer from './socket/reducer';
import inClassReducer from './inClass/reducer';
import tableReducer from './table/reducer';
import htmlSlidesReducer from './htmlSlides/reducer';
import slideStatusReducer from './slideStatus/reducer';

//inclass
import contentInClassReducer from './inClass/content/reducer';

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  StudentGuardianLogin: StudentGuardianLoginReducer,
  StudentGuardianRegistration: StudentGuardianRegisterReducer,
  StudentGuardianForgotPasscode: StudentGuardianForgotPasscodeReducer,
  StudentGuardianDashboard: StudentGuardianDashboardReducer,
  StudentGuardianProfile: StudentGuardianProfileReducer,
  Utils: UtilsReducer,
  AdminAuth: adminAuthReducer,
  AdminSchool: adminSchoolsReducer,
  AdminSchoolCurriculum: adminSchoolCurriculumReducer,
  AdminClass: adminClassReducer,
  AdminBatch: adminBatchReducer,
  AdminUser: adminUserReducer,
  AdminTutor: adminTutorReducer,
  AdminDemoClass: adminDemoClassReducer,
  Sanity: sanityReducer,
  AdminConfiguration: adminConfigurationReducer,
  Student: studentReducer,
  AdminDashboard: adminDashboardReducer,
  AdminBook: adminBookReducer,
  AdminChapter: adminChapterReducer,
  AdminTopic: adminTopicReducer,
  AdminSegment: adminSegmentReducer,
  AdminItem: adminItemReducer,
  AdminSubtopic: adminSubtopicReducer,
  SocketInstance: socketInstanceReducer,
  InClass: inClassReducer,
  Table: tableReducer,
  ContentInClass: contentInClassReducer,
  HTMLSlides: htmlSlidesReducer,
  SlideStatus: slideStatusReducer,
});

export default rootReducer;
