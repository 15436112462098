import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  Classes: [],
  classDetails: {},
  studentsUnderstanding: {},
  loading: false,
  addOrUpdateloading: { loader: false, success: null, error: null },
  bookDemoLoading: { loader: false, success: null, error: null },
  practiceClassLoading: { loader: false, success: null, error: null },
  classConfiguration: {},
  errors: null,
  lecture: {},
  setRawLecture: [],
  currentLectureIndex: 0,
};

const adminClassSlice = createSlice({
  name: 'ClassesList',
  initialState,
  reducers: {
    setClassesList: (state, action) => {
      state.Classes = action.payload;
      state.loading = initialState.loading;
    },
    setRawLecture: (state, action) => {
      state.setRawLecture = action.payload;
    },
    setCurrentLectureIndex: (state, action) => {
      state.currentLectureIndex = action.payload;
    },
    setClassDetails: (state, action) => {
      state.classDetails = action.payload;
      state.loading = initialState.loading;
    },
    setStudentsUnderstanding: (state, action) => {
      state.studentsUnderstanding = action.payload;
      state.loading = initialState.loading;
    },
    updateStudentsUnderstanding: (state, action) => {
      state.studentsUnderstanding[action.payload.key] = action.payload.value;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setAddOrUpdateLoader: (state, action) => {
      state.addOrUpdateloading = action.payload;
    },
    setBookDemoLoading: (state, action) => {
      state.bookDemoLoading = action.payload;
    },
    setPracticeClassLoading: (state, action) => {
      state.practiceClassLoading = action.payload;
    },
    setClassConfiguration: (state, action) => {
      state.classConfiguration = action.payload;
    },
    reset: (state) => {
      state.addOrUpdateloading = initialState.addOrUpdateloading;
      state.bookDemoLoading = initialState.bookDemoLoading;
      state.practiceClassLoading = initialState.practiceClassLoading;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload;
      state.loading = initialState.loading;
    },
    setLecture: (state, action) => {
      state.lecture = action.payload;
    },
  },
});

export const getClassesListRedux = (state) => state.AdminClass.Classes;
export const getClassesListLoaderRedux = (state) => state.AdminClass.loading;
export const getClassDetailsRedux = (state) => state.AdminClass.classDetails;
export const getStudentsUnderstandingRedux = (state) =>
  state.AdminClass.studentsUnderstanding;
export const getAddOrUpdateClassLoaderRedux = (state) =>
  state.AdminClass.addOrUpdateloading;
export const getBookDemoClassLoaderRedux = (state) =>
  state.AdminClass.bookDemoLoading;
export const getPracticeClassLoaderRedux = (state) =>
  state.AdminClass.practiceClassLoading;
export const catchErrorsClassRedux = (state) =>
  state?.AdminClass?.errors || null;
export const getClassConfigurationRedux = (state) =>
  state.AdminClass.classConfiguration;
export const getLectureRedux = (state) => state.AdminClass.lecture;
export const getRawLectureRedux = (state) => state.AdminClass.setRawLecture;
export const getCurrentLectureIndexRedux = (state) =>
  state.AdminClass.currentLectureIndex;

export const {
  setClassesList,
  setLoader,
  setClassDetails,
  setStudentsUnderstanding,
  updateStudentsUnderstanding,
  setAddOrUpdateLoader,
  setBookDemoLoading,
  setPracticeClassLoading,
  setClassConfiguration,
  setLecture,
  setRawLecture,
  setCurrentLectureIndex,
  reset,
  catchErrors,
} = adminClassSlice.actions;

export default adminClassSlice.reducer;
