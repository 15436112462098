import { getEvaluationSummary } from 'helpers/evaluation';
import { setLectureData, setPollData, setSlideNumbers } from './reducer';

export const setClassContent = (data) => async (dispatch) => {
  try {
    const lectureData = await dispatch(setLectureData(data));
    function processPresentationData(data) {
      const result = {
        teach: { lastSlideNo: 0, slideData: [] },
        coach_basic: { lastSlideNo: 0, slideData: [] },
        coach_intermediate: { lastSlideNo: 0, slideData: [] },
        coach_advanced: { lastSlideNo: 0, slideData: [] },
      };
      function processArray(array, targetObject) {
        let slideCounter = 1;
        array
          .filter((item) => item.type !== 'evaluation')
          .forEach((item) => {
            const slideNoObj = {};
            item?.thumbnails?.forEach((thumbnail) => {
              slideNoObj[thumbnail.slideNo] = slideCounter++;
            });
            const slideNumbers = Object.values(slideNoObj);
            const processedItem = {
              id: item?.presentationId,
              name: item?.elementDetails?.name,
              firstSlideNo: Math.min(...slideNumbers),
              lastSlideNo: Math.max(...slideNumbers),
              slideNo: slideNoObj,
            };
            targetObject.slideData.push(processedItem);
            targetObject.lastSlideNo = processedItem.lastSlideNo;
          });
      }
      if (data.teach) {
        processArray(data.teach, result.teach);
      }
      const coachArrays = [
        'coach_basic',
        'coach_intermediate',
        'coach_advanced',
      ];
      coachArrays.forEach((coachArray) => {
        if (data[coachArray]) {
          processArray(data[coachArray], result[coachArray]);
        }
      });
      return result;
    }
    const slidesData = processPresentationData(lectureData.payload);
    dispatch(setSlideNumbers(slidesData));
  } catch (err) {
    console.log('error in setting slide numbers', err);
  }
};

export const syncPoll = (data) => async (dispatch) => {
  const { classId, evaluationType, studentIds } = data;
  try {
    const response = await getEvaluationSummary({
      classId,
      evaluationType,
      studentIds,
    });
    dispatch(setPollData(response?.data || []));
  } catch (err) {
    console.log('error in setting poll data', err);
  }
};
